var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CSidebar",
    {
      attrs: {
        aside: "",
        show: _vm.$store.state.sendRestaurantDetailsAsideShow,
        colorScheme: "light",
        overlaid: "",
        size: "lg",
      },
      on: {
        "update:show": (val) =>
          _vm.$store.commit("set", ["sendRestaurantDetailsAsideShow", val]),
      },
    },
    [
      _c("CSidebarClose", {
        nativeOn: {
          click: function ($event) {
            return _vm.$store.commit("toggle", "sendRestaurantDetailsAsideShow")
          },
        },
      }),
      _c(
        "CListGroup",
        { staticClass: "scrollable list-group-accent mt-5" },
        [
          _c(
            "CListGroupItem",
            {
              staticClass:
                "mb-0 list-group-item-accent-primary bg-light text-center font-weight-bold text-uppercase",
            },
            [
              _vm._v(
                " Send " + _vm._s(_vm._f("normalizeSentence")(_vm.detail)) + " "
              ),
            ]
          ),
          _c(
            "CListGroupItem",
            { staticClass: "list-group-item-accent-secondary" },
            [
              _c("h5", [_vm._v("Select emails to send:")]),
              _c(
                "ul",
                { staticClass: "checklist" },
                _vm._l(_vm.emails, function (mail, index) {
                  return _c("li", [
                    _c("label", { staticClass: "for-checkbox" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedEmails,
                            expression: "selectedEmails",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          value: mail,
                          checked: Array.isArray(_vm.selectedEmails)
                            ? _vm._i(_vm.selectedEmails, mail) > -1
                            : _vm.selectedEmails,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.selectedEmails,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = mail,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.selectedEmails = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.selectedEmails = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.selectedEmails = $$c
                            }
                          },
                        },
                      }),
                      _vm._v(" " + _vm._s(mail) + " "),
                    ]),
                  ])
                }),
                0
              ),
            ]
          ),
          _c(
            "CListGroupItem",
            {
              staticClass:
                "sticky-bottom list-group-item-accent-primary bg-white",
            },
            [
              _c(
                "CButton",
                {
                  attrs: {
                    color: "primary",
                    disabled: !_vm.selectedEmails.length,
                  },
                  on: { click: _vm.send },
                },
                [
                  _c("CIcon", { attrs: { name: "cil-send" } }),
                  _vm._v(" Send "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("CElementCover", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        attrs: { opacity: 0.4 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }