var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CSidebar",
    {
      attrs: {
        aside: "",
        show: _vm.$store.state.latestRestaurantActivitiesAsideShow,
        colorScheme: "light",
        overlaid: "",
        size: "lg",
      },
      on: {
        "update:show": (val) =>
          _vm.$store.commit("set", [
            "latestRestaurantActivitiesAsideShow",
            val,
          ]),
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-between align-items-center",
          staticStyle: { height: "56px" },
        },
        [
          _c(
            "CButton",
            {
              staticClass: "text-danger",
              attrs: { color: "link", variant: "ghost" },
              on: {
                click: function ($event) {
                  return _vm.showAllActivities()
                },
              },
            },
            [
              _c("CIcon", { attrs: { name: "cil-fingerprint", size: "xl" } }),
              _vm._v(" Show all activities "),
            ],
            1
          ),
          _c("CSidebarClose", {
            nativeOn: {
              click: function ($event) {
                return _vm.$store.commit(
                  "toggle",
                  "latestRestaurantActivitiesAsideShow"
                )
              },
            },
          }),
        ],
        1
      ),
      _c(
        "CListGroup",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showErrorAlert,
              expression: "showErrorAlert",
            },
          ],
          staticClass: "list-group-accent",
        },
        [
          _c(
            "CListGroupItem",
            {
              staticClass:
                "list-group-item-accent-secondary bg-light text-danger text-center font-weight-bold text-uppercase",
            },
            [_vm._v(" Error ")]
          ),
          _c(
            "CListGroupItem",
            {
              staticClass:
                "list-group-item-accent-danger list-group-item-divider",
              attrs: { href: "#" },
            },
            [
              _c(
                "div",
                { staticClass: "c-avatar float-left mr-2" },
                [
                  _c("CIcon", {
                    staticClass: "c-avatar-img text-danger",
                    attrs: { name: "cil-bug", size: "xl" },
                  }),
                ],
                1
              ),
              _c("div", [_vm._v(_vm._s(_vm.errorAlertMessage))]),
            ]
          ),
        ],
        1
      ),
      _c(
        "CListGroup",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showErrorAlert,
              expression: "!showErrorAlert",
            },
          ],
          staticClass: "scrollable list-group-accent",
        },
        [
          _c(
            "CListGroupItem",
            {
              staticClass:
                "list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase",
            },
            [_vm._v(" Latest Activity Logs ")]
          ),
          _vm._l(_vm.logs, function (activity) {
            return [
              _c("CListGroupItem", { staticClass: "list-group-item-divider" }, [
                _c(
                  "div",
                  [
                    _c("CIcon", {
                      staticClass: "mr-1",
                      attrs: { name: "cil-bolt" },
                    }),
                    _vm._v(_vm._s(activity?.event)),
                  ],
                  1
                ),
                _c("div", { staticClass: "small text-muted mt-1" }, [
                  _vm._v(_vm._s(activity?.description)),
                ]),
                _c(
                  "div",
                  { staticClass: "small text-muted mt-1" },
                  [
                    _c("CIcon", {
                      staticClass: "mr-2",
                      attrs: { name: "cil-calendar" },
                    }),
                    _vm._v(
                      " " +
                        _vm._s(_vm.moment(activity.created_at).calendar()) +
                        " "
                    ),
                  ],
                  1
                ),
              ]),
            ]
          }),
        ],
        2
      ),
      _c("CElementCover", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        attrs: { opacity: 0.4 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }