var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.item
        ? _c(
            "CCard",
            [
              _c(
                "CCardHeader",
                [
                  _c("CIcon", { attrs: { name: "cil-restaurant" } }),
                  _c("h5", { staticClass: "d-inline ml-2" }, [
                    _vm._v(" " + _vm._s(_vm.item?.restaurant_name) + " "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "card-header-actions d-flex flex-gap-1" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex align-items-center border-right",
                        },
                        [
                          _vm.item.restaurant_slug
                            ? _c(
                                "CButton",
                                {
                                  directives: [
                                    {
                                      name: "c-tooltip",
                                      rawName: "v-c-tooltip",
                                      value: {
                                        content: _vm.item.restaurant_slug_path,
                                      },
                                      expression:
                                        "{ content: item.restaurant_slug_path }",
                                    },
                                  ],
                                  attrs: {
                                    variant: "ghost",
                                    shape: "pill",
                                    target: "_blank",
                                    href: _vm._f("addUriScheme")(
                                      _vm.item.restaurant_slug_path
                                    ),
                                  },
                                },
                                [
                                  _c("CIcon", {
                                    attrs: { name: "cil-restaurant" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.item.website_etenonline_be
                            ? _c(
                                "CButton",
                                {
                                  directives: [
                                    {
                                      name: "c-tooltip",
                                      rawName: "v-c-tooltip",
                                      value: {
                                        content: _vm.item.website_etenonline_be,
                                      },
                                      expression:
                                        "{ content: item.website_etenonline_be }",
                                    },
                                  ],
                                  attrs: {
                                    variant: "ghost",
                                    shape: "pill",
                                    target: "_blank",
                                    href: _vm._f("addUriScheme")(
                                      _vm.item.website_etenonline_be
                                    ),
                                  },
                                },
                                [_c("CIcon", { attrs: { name: "cil-link" } })],
                                1
                              )
                            : _vm._e(),
                          _vm.item.website
                            ? _c(
                                "CButton",
                                {
                                  directives: [
                                    {
                                      name: "c-tooltip",
                                      rawName: "v-c-tooltip",
                                      value: { content: _vm.item.website },
                                      expression: "{ content: item.website }",
                                    },
                                  ],
                                  staticClass: "mr-1",
                                  attrs: {
                                    variant: "ghost",
                                    shape: "pill",
                                    target: "_blank",
                                    href: _vm._f("addUriScheme")(
                                      _vm.item.website
                                    ),
                                  },
                                },
                                [
                                  _c("CIcon", {
                                    attrs: { name: "cil-external-link" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center flex-gap-1" },
                        [
                          _c(
                            "CButton",
                            {
                              directives: [
                                {
                                  name: "c-tooltip",
                                  rawName: "v-c-tooltip",
                                  value: {
                                    content:
                                      "Latest Activities Caused by Restaurant",
                                  },
                                  expression:
                                    "{ content: 'Latest Activities Caused by Restaurant' }",
                                },
                              ],
                              attrs: {
                                color: "danger",
                                variant: "ghost",
                                shape: "pill",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.showLatestActivities()
                                },
                              },
                            },
                            [
                              _c("CIcon", {
                                attrs: { name: "cil-fingerprint", size: "lg" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "CButton",
                            {
                              directives: [
                                {
                                  name: "c-tooltip",
                                  rawName: "v-c-tooltip",
                                  value: { content: "Restaurant Messages" },
                                  expression:
                                    "{ content: 'Restaurant Messages' }",
                                },
                              ],
                              attrs: {
                                color: "primary",
                                variant: "ghost",
                                shape: "pill",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.showMessages()
                                },
                              },
                            },
                            [
                              _c("CIcon", {
                                attrs: {
                                  name: "cil-envelope-closed",
                                  size: "lg",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-items-center flex-gap-1 border-left",
                        },
                        [
                          _c(
                            "CButton",
                            {
                              directives: [
                                {
                                  name: "c-tooltip",
                                  rawName: "v-c-tooltip",
                                  value: { content: "Edit Restaurant" },
                                  expression: "{ content: 'Edit Restaurant' }",
                                },
                              ],
                              staticClass: "ml-1",
                              attrs: {
                                color: "info",
                                variant: "ghost",
                                shape: "pill",
                                to: {
                                  name: "Edit Restaurant",
                                  params: { id: _vm.itemId },
                                },
                              },
                            },
                            [
                              _c("CIcon", {
                                attrs: { name: "cil-align-left", size: "lg" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "CButton",
                            { attrs: { color: "dark", to: _vm.prevRoute } },
                            [
                              _c("CIcon", {
                                staticClass: "align-bottom",
                                attrs: { name: "cil-arrow-thick-left" },
                              }),
                              _vm._v(" Back "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "CCardBody",
                { staticClass: "pb-0" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column flex-md-row flex-gap-2 mb-4",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex-grow-1" },
                        [
                          _vm.item.email && !_vm.item.upload_contract
                            ? _c("UnsignedContract", {
                                attrs: {
                                  id: _vm.item.id,
                                  email: _vm.item.email,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _vm.item.hasdeliveryService
                            ? _c(
                                "CAlert",
                                {
                                  staticClass:
                                    "d-flex flex-gap-2 align-items-center mb-0 h-100",
                                  attrs: { color: "success" },
                                },
                                [
                                  _c("CIcon", {
                                    attrs: { name: "cil-truck", size: "2xl" },
                                  }),
                                  _c("div", [
                                    _c("strong", [
                                      _vm._v("Delivery Service enabled."),
                                    ]),
                                    !_vm.item.hubs_count
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-gap-2 align-items-center text-danger font-weight-bold flash-it",
                                          },
                                          [
                                            _vm._v(
                                              " ⚠ Add the postcode (" +
                                                _vm._s(
                                                  _vm.item.restaurant_postcode
                                                ) +
                                                ") to the scope of a hub. "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("alert-section", {
                    attrs: {
                      successAlertMessage: _vm.successAlertMessage,
                      dismissSecs: _vm.dismissSecs,
                      dismissSuccessAlert: _vm.dismissSuccessAlert,
                      errorAlertMessage: _vm.errorAlertMessage,
                      showErrorAlert: _vm.showErrorAlert,
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "resto-header d-flex flex-column flex-sm-row align-items-center justify-content-between mb-4 p-3 h-100 flex-gap-3",
                      class: {
                        "table-danger": _vm.item.none_active,
                        "table-success": !_vm.item.none_active,
                      },
                      style: {
                        backgroundImage:
                          "url(" + _vm.item.hero_image?.image_url + ")",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "logo" },
                        [
                          _c("CImg", {
                            staticClass: "logo__image",
                            class: {
                              "resto-none-active": _vm.item.none_active,
                              "resto-active": !_vm.item.none_active,
                            },
                            attrs: {
                              src: _vm.getValidImageUrl(
                                _vm.item.restaurant_logo_path
                              ),
                              placeholderColor: "lightgray",
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "logo__content",
                              attrs: { role: "button" },
                              on: { click: _vm.changelogo },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "logo__icon" },
                                [
                                  _c("CIcon", {
                                    attrs: { name: "cil-camera", size: "xl" },
                                  }),
                                ],
                                1
                              ),
                              _c("span", { staticClass: "logo__text" }, [
                                _vm._v("Change Logo"),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "p-2 address-container" }, [
                        _c("address", { staticClass: "blockquote" }, [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.item.restaurant_name)),
                          ]),
                          _c("br"),
                          _vm._v(
                            " " + _vm._s(_vm.item.restaurant_address) + " "
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(_vm.item.restaurant_city) +
                              ", " +
                              _vm._s(_vm.item.countryId) +
                              " " +
                              _vm._s(_vm.item.restaurant_postcode)
                          ),
                          _c("br"),
                          _c(
                            "abbr",
                            { attrs: { title: "Phone Number" } },
                            [
                              _c("CIcon", { attrs: { name: "cil-phone" } }),
                              _vm._v(": " + _vm._s(_vm.item.restaurant_tel)),
                            ],
                            1
                          ),
                          _c("br"),
                          _c(
                            "abbr",
                            { attrs: { title: "Mobile Number" } },
                            [
                              _c("CIcon", { attrs: { name: "cil-mobile" } }),
                              _vm._v(": " + _vm._s(_vm.item.restaurant_gsm)),
                            ],
                            1
                          ),
                        ]),
                        _c("address", { staticClass: "mb-0 blockquote" }, [
                          _c(
                            "abbr",
                            { attrs: { title: "Email" } },
                            [
                              _c("CIcon", { attrs: { name: "cil-at" } }),
                              _vm._v(": "),
                              _c(
                                "a",
                                { attrs: { href: `mailto:${_vm.item.email}` } },
                                [_vm._v(_vm._s(_vm.item.email))]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "button ml-n2" },
                          [
                            _c(
                              "CLink",
                              {
                                attrs: {
                                  to: {
                                    name: "Edit Restaurant",
                                    params: { id: _vm.item.id },
                                    query: { tab: 0 },
                                  },
                                },
                              },
                              [
                                _c("CIcon", { attrs: { name: "cil-pen" } }),
                                _vm._v(" Edit Contact Info "),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "action",
                          on: { click: _vm.selectHeroImage },
                        },
                        [_vm._v("Change Hero Image")]
                      ),
                    ]
                  ),
                  _c("h5", { staticClass: "mb-0 mt-5" }, [
                    _vm._v("Restaurant Settings"),
                  ]),
                  _c("p", [
                    _vm._v(
                      "These settings help you quickly change important configs."
                    ),
                  ]),
                  _c("div", { staticClass: "list-group mb-5 shadow" }, [
                    _c("div", { staticClass: "list-group-item" }, [
                      _c("div", { staticClass: "row align-items-center" }, [
                        _c("div", { staticClass: "col" }, [
                          _c("strong", { staticClass: "mb-2" }, [
                            _vm._v("Not Published"),
                          ]),
                          _c("p", { staticClass: "text-muted mb-0" }, [
                            _vm._v(
                              "This setting controls whether the restaurant is actively published."
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-auto" },
                          [
                            _c("CSwitch", {
                              attrs: {
                                checked: _vm.item.none_active,
                                labelOn: "YES",
                                labelOff: "NO",
                                color: "danger",
                                shape: "pill",
                              },
                              on: {
                                "update:checked": [
                                  function ($event) {
                                    return _vm.$set(
                                      _vm.item,
                                      "none_active",
                                      $event
                                    )
                                  },
                                  _vm.updateRestaurantStatus,
                                ],
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "list-group-item" }, [
                      _c("div", { staticClass: "row align-items-center" }, [
                        _c("div", { staticClass: "col" }, [
                          _c("strong", { staticClass: "mb-2" }, [
                            _vm._v("Today Closed"),
                          ]),
                          _c("p", { staticClass: "text-muted mb-0" }, [
                            _vm._v(
                              "Quickly change if the place is open or closed."
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-auto" },
                          [
                            _c("CSwitch", {
                              attrs: {
                                checked: _vm.item.rest_closed_today,
                                labelOn: "YES",
                                labelOff: "NO",
                                color: "success",
                                shape: "pill",
                              },
                              on: {
                                "update:checked": [
                                  function ($event) {
                                    return _vm.$set(
                                      _vm.item,
                                      "rest_closed_today",
                                      $event
                                    )
                                  },
                                  function ($event) {
                                    return _vm.updateSettings({
                                      rest_closed_today:
                                        _vm.item.rest_closed_today,
                                    })
                                  },
                                ],
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "list-group-item" }, [
                      _c("div", { staticClass: "row align-items-center" }, [
                        _c("div", { staticClass: "col" }, [
                          _c("strong", { staticClass: "mb-2" }, [
                            _vm._v("Custom Opening Times"),
                          ]),
                          _c("p", { staticClass: "text-muted mb-0" }, [
                            _vm._v(
                              "Easily set customized opening hours for the restaurant."
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-auto" },
                          [
                            _c(
                              "CButton",
                              {
                                attrs: {
                                  to: {
                                    name: "RestaurantOpeningTimes",
                                    params: { restaurant_id: _vm.item.id },
                                  },
                                  size: "sm",
                                  color: "info",
                                  variant: "ghost",
                                  shape: "pill",
                                },
                              },
                              [
                                _c("CIcon", {
                                  attrs: { name: "cil-align-left" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "list-group-item" }, [
                      _c("div", { staticClass: "row align-items-center" }, [
                        _c("div", { staticClass: "col" }, [
                          _c(
                            "strong",
                            {
                              staticClass: "mb-2",
                              class: {
                                "text-success": _vm.item.hasdeliveryService,
                              },
                            },
                            [
                              _c("CIcon", { attrs: { name: "cil-truck" } }),
                              _vm._v(" Delivery Service "),
                            ],
                            1
                          ),
                          _c("p", { staticClass: "text-muted mb-0" }, [
                            _vm._v("Update Menute Delivery Service status."),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-auto" },
                          [
                            _c(
                              "CButton",
                              {
                                attrs: {
                                  to: { name: "DeliveryService" },
                                  size: "sm",
                                  color: "info",
                                  variant: "ghost",
                                  shape: "pill",
                                },
                              },
                              [
                                _c("CIcon", {
                                  attrs: { name: "cil-align-left" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "list-group-item" }, [
                      _c("div", { staticClass: "row align-items-center" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c("strong", { staticClass: "mb-2 mr-2" }, [
                              _vm._v(
                                "Send Restaurant Login Details to Holders"
                              ),
                            ]),
                            _c(
                              "CBadge",
                              { attrs: { shape: "pill", color: "info" } },
                              [_vm._v("NEW")]
                            ),
                            _c("p", { staticClass: "text-muted mb-0" }, [
                              _vm._v(
                                "Send the restaurant's username and password directly to the restaurant emails."
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-auto" },
                          [
                            _c(
                              "CButton",
                              {
                                attrs: {
                                  size: "sm",
                                  color: "info",
                                  variant: "ghost",
                                  shape: "pill",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.sendRestaurantDetails(
                                      "login-details"
                                    )
                                  },
                                },
                              },
                              [_c("CIcon", { attrs: { name: "cil-send" } })],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "list-group-item" }, [
                      _c("div", { staticClass: "row align-items-center" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c("strong", { staticClass: "mb-2 mr-2" }, [
                              _vm._v("Send Magicsoftware Link to Holders"),
                            ]),
                            _c(
                              "CBadge",
                              { attrs: { shape: "pill", color: "info" } },
                              [_vm._v("NEW")]
                            ),
                            _c("p", { staticClass: "text-muted mb-0" }, [
                              _vm._v(
                                "Send Magicsoftware Link directly to the restaurant emails."
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-auto" },
                          [
                            _c(
                              "CButton",
                              {
                                attrs: {
                                  size: "sm",
                                  color: "info",
                                  variant: "ghost",
                                  shape: "pill",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.sendRestaurantDetails(
                                      "magicsoftware-link"
                                    )
                                  },
                                },
                              },
                              [_c("CIcon", { attrs: { name: "cil-send" } })],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        {
                          staticClass: "widget",
                          attrs: { col: "12", sm: "6", xl: "3" },
                        },
                        [
                          _c(
                            "CWidgetIcon",
                            {
                              attrs: {
                                header: "Account Balance",
                                text: String(this.item.account_balance),
                                color: "gradient-dark",
                                "icon-padding": false,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "footer",
                                    fn: function () {
                                      return [
                                        _c(
                                          "CCardFooter",
                                          [
                                            _c(
                                              "CLink",
                                              {
                                                staticClass:
                                                  "font-sm text-muted d-flex justify-content-between",
                                                attrs: {
                                                  to: {
                                                    name: "RestaurantPaymentList",
                                                    params: {
                                                      restaurant_id:
                                                        _vm.item.id,
                                                    },
                                                  },
                                                  target: "_self",
                                                },
                                              },
                                              [
                                                _vm._v(" Show Payments "),
                                                _c("CIcon", {
                                                  attrs: {
                                                    name: "cil-arrow-right",
                                                    width: "16",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                2444226741
                              ),
                            },
                            [
                              _c("CIcon", {
                                attrs: { name: "cil-euro", width: "24" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        {
                          staticClass: "widget",
                          attrs: { col: "12", sm: "6", xl: "3" },
                        },
                        [
                          _c(
                            "CWidgetIcon",
                            {
                              attrs: {
                                header: "Orders",
                                text: String(this.item.restaurant_orders_count),
                                color: "gradient-dark",
                                "icon-padding": false,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "footer",
                                    fn: function () {
                                      return [
                                        _c(
                                          "CCardFooter",
                                          [
                                            _c(
                                              "CLink",
                                              {
                                                staticClass:
                                                  "font-sm text-muted d-flex justify-content-between",
                                                attrs: {
                                                  to: {
                                                    name: "RestaurantOrderList",
                                                    params: {
                                                      restaurant_id:
                                                        _vm.item.id,
                                                    },
                                                  },
                                                  target: "_self",
                                                },
                                              },
                                              [
                                                _vm._v(" Show Orders "),
                                                _c("CIcon", {
                                                  attrs: {
                                                    name: "cil-arrow-right",
                                                    width: "16",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1757570549
                              ),
                            },
                            [
                              _c("CIcon", {
                                attrs: { name: "cil-cart", width: "24" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        {
                          staticClass: "widget",
                          attrs: { col: "12", sm: "6", xl: "3" },
                        },
                        [
                          _c(
                            "CWidgetIcon",
                            {
                              attrs: {
                                header: "Reviews",
                                text: String(
                                  this.item.restaurant_reviews_count
                                ),
                                color: "gradient-dark",
                                "icon-padding": false,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "footer",
                                    fn: function () {
                                      return [
                                        _c(
                                          "CCardFooter",
                                          [
                                            _c(
                                              "CLink",
                                              {
                                                staticClass:
                                                  "font-sm text-muted d-flex justify-content-between",
                                                attrs: {
                                                  to: {
                                                    name: "RestaurantReviews",
                                                    params: {
                                                      restaurant_id:
                                                        _vm.item.id,
                                                    },
                                                  },
                                                  target: "_self",
                                                },
                                              },
                                              [
                                                _vm._v(" Show Reviews "),
                                                _c("CIcon", {
                                                  attrs: {
                                                    name: "cil-arrow-right",
                                                    width: "16",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                683534372
                              ),
                            },
                            [
                              _c("CIcon", {
                                attrs: { name: "cil-star", width: "24" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        {
                          staticClass: "widget",
                          attrs: { col: "12", sm: "6", xl: "3" },
                        },
                        [
                          _c(
                            "CWidgetIcon",
                            {
                              attrs: {
                                header: "Zip Codes",
                                text: String(
                                  this.item.zip_codes_linked_restaurants_count
                                ),
                                color: "gradient-dark",
                                "icon-padding": false,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "footer",
                                    fn: function () {
                                      return [
                                        _c(
                                          "CCardFooter",
                                          [
                                            _c(
                                              "CLink",
                                              {
                                                staticClass:
                                                  "font-sm text-muted d-flex justify-content-between",
                                                attrs: {
                                                  to: {
                                                    name: "RestaurantLinkedZIPCodes",
                                                    params: {
                                                      restaurant_id:
                                                        _vm.item.id,
                                                    },
                                                  },
                                                  target: "_self",
                                                },
                                              },
                                              [
                                                _vm._v(" Show Zip Codes "),
                                                _c("CIcon", {
                                                  attrs: {
                                                    name: "cil-arrow-right",
                                                    width: "16",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                3473711237
                              ),
                            },
                            [
                              _c("CIcon", {
                                attrs: {
                                  name: "cil-location-pin",
                                  width: "24",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        {
                          staticClass: "widget",
                          attrs: { col: "12", sm: "6", xl: "3" },
                        },
                        [
                          _c(
                            "CWidgetIcon",
                            {
                              attrs: {
                                header: "Linked Types",
                                text: String(
                                  this.item.restaurant_linked_types_count
                                ),
                                color: "gradient-dark",
                                "icon-padding": false,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "footer",
                                    fn: function () {
                                      return [
                                        _c(
                                          "CCardFooter",
                                          [
                                            _c(
                                              "CLink",
                                              {
                                                staticClass:
                                                  "font-sm text-muted d-flex justify-content-between",
                                                attrs: {
                                                  to: {
                                                    name: "RestaurantLinkedTypes",
                                                    params: {
                                                      restaurant_id:
                                                        _vm.item.id,
                                                    },
                                                  },
                                                  target: "_self",
                                                },
                                              },
                                              [
                                                _vm._v(" Show Linked Types "),
                                                _c("CIcon", {
                                                  attrs: {
                                                    name: "cil-arrow-right",
                                                    width: "16",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                3005884516
                              ),
                            },
                            [
                              _c("CIcon", {
                                attrs: { name: "cil-text", width: "24" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        {
                          staticClass: "widget",
                          attrs: { col: "12", sm: "6", xl: "3" },
                        },
                        [
                          _c(
                            "CWidgetIcon",
                            {
                              attrs: {
                                header: "Categories",
                                text: String(this.item.categories_count),
                                color: "gradient-dark",
                                "icon-padding": false,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "footer",
                                    fn: function () {
                                      return [
                                        _c(
                                          "CCardFooter",
                                          [
                                            _c(
                                              "CLink",
                                              {
                                                staticClass:
                                                  "font-sm text-muted d-flex justify-content-between",
                                                attrs: {
                                                  to: {
                                                    name: "RestaurantLinkedCategories",
                                                    params: {
                                                      restaurant_id:
                                                        _vm.item.id,
                                                    },
                                                  },
                                                  target: "_self",
                                                },
                                              },
                                              [
                                                _vm._v(" Show Categories "),
                                                _c("CIcon", {
                                                  attrs: {
                                                    name: "cil-arrow-right",
                                                    width: "16",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                578013253
                              ),
                            },
                            [
                              _c("CIcon", {
                                attrs: { name: "cil-folder", width: "24" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        {
                          staticClass: "widget",
                          attrs: { col: "12", sm: "6", xl: "3" },
                        },
                        [
                          _c(
                            "CWidgetIcon",
                            {
                              attrs: {
                                header: "Menus",
                                text: String(this.item.menus_count),
                                color: "gradient-dark",
                                "icon-padding": false,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "footer",
                                    fn: function () {
                                      return [
                                        _c(
                                          "CCardFooter",
                                          [
                                            _c(
                                              "CLink",
                                              {
                                                staticClass:
                                                  "font-sm text-muted d-flex justify-content-between",
                                                attrs: {
                                                  to: {
                                                    name: "RestaurantLinkedMenus",
                                                    params: {
                                                      restaurant_id:
                                                        _vm.item.id,
                                                    },
                                                  },
                                                  target: "_self",
                                                },
                                              },
                                              [
                                                _vm._v(" Show Menus "),
                                                _c("CIcon", {
                                                  attrs: {
                                                    name: "cil-arrow-right",
                                                    width: "16",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1897857413
                              ),
                            },
                            [
                              _c("CIcon", {
                                attrs: { name: "cil-dinner", width: "24" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        {
                          staticClass: "widget",
                          attrs: { col: "12", sm: "6", xl: "3" },
                        },
                        [
                          _c(
                            "CWidgetIcon",
                            {
                              attrs: {
                                header: "Menu Sjabloons",
                                text: String(this.item.menu_sjabloons_count),
                                color: "gradient-dark",
                                "icon-padding": false,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "footer",
                                    fn: function () {
                                      return [
                                        _c(
                                          "CCardFooter",
                                          [
                                            _c(
                                              "CLink",
                                              {
                                                staticClass:
                                                  "font-sm text-muted d-flex justify-content-between",
                                                attrs: {
                                                  to: {
                                                    name: "Restaurant Menu Sjabloons",
                                                    params: {
                                                      restaurant_id:
                                                        _vm.item.id,
                                                    },
                                                  },
                                                  target: "_self",
                                                },
                                              },
                                              [
                                                _vm._v(" Show Menu Sjabloons "),
                                                _c("CIcon", {
                                                  attrs: {
                                                    name: "cil-arrow-right",
                                                    width: "16",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                2429210564
                              ),
                            },
                            [
                              _c("CIcon", {
                                attrs: {
                                  name: "cil-object-ungroup",
                                  width: "24",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        {
                          staticClass: "widget",
                          attrs: { col: "12", sm: "6", xl: "3" },
                        },
                        [
                          _c(
                            "CWidgetIcon",
                            {
                              attrs: {
                                header: "Tasks",
                                text: `${this.item.open_tasks_count} / ${this.item.tasks_count}`,
                                color: "gradient-dark",
                                "icon-padding": false,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "footer",
                                    fn: function () {
                                      return [
                                        _c(
                                          "CCardFooter",
                                          [
                                            _c(
                                              "CLink",
                                              {
                                                staticClass:
                                                  "font-sm text-muted d-flex justify-content-between",
                                                attrs: {
                                                  to: {
                                                    name: "RestaurantTaskList",
                                                    params: {
                                                      restaurant_id:
                                                        _vm.item.id,
                                                    },
                                                  },
                                                  target: "_self",
                                                },
                                              },
                                              [
                                                _vm._v(" Show Tasks "),
                                                _c("CIcon", {
                                                  attrs: {
                                                    name: "cil-arrow-right",
                                                    width: "16",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                3172774069
                              ),
                            },
                            [
                              _c("CIcon", {
                                attrs: { name: "cil-bolt", width: "24" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        {
                          staticClass: "widget",
                          attrs: { col: "12", sm: "6", xl: "3" },
                        },
                        [
                          _c(
                            "CWidgetIcon",
                            {
                              attrs: {
                                header: "Permissions",
                                text: `${this.item.permissions_count}`,
                                color: "gradient-dark",
                                "icon-padding": false,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "footer",
                                    fn: function () {
                                      return [
                                        _c(
                                          "CCardFooter",
                                          [
                                            _c(
                                              "CLink",
                                              {
                                                staticClass:
                                                  "font-sm text-muted d-flex justify-content-between",
                                                attrs: {
                                                  to: {
                                                    name: "RestaurantPermissionList",
                                                    params: {
                                                      restaurant_id:
                                                        _vm.item.id,
                                                    },
                                                  },
                                                  target: "_self",
                                                },
                                              },
                                              [
                                                _vm._v(" Show Permissions "),
                                                _c("CIcon", {
                                                  attrs: {
                                                    name: "cil-arrow-right",
                                                    width: "16",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                2859601589
                              ),
                            },
                            [
                              _c("CIcon", {
                                attrs: {
                                  name: "cil-lock-unlocked",
                                  width: "24",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        {
                          staticClass: "widget",
                          attrs: { col: "12", sm: "6", xl: "3" },
                        },
                        [
                          _c(
                            "CWidgetIcon",
                            {
                              attrs: {
                                header: "Revenues",
                                color: "gradient-dark",
                                "icon-padding": false,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "footer",
                                    fn: function () {
                                      return [
                                        _c(
                                          "CCardFooter",
                                          [
                                            _c(
                                              "CLink",
                                              {
                                                staticClass:
                                                  "font-sm text-muted d-flex justify-content-between",
                                                attrs: {
                                                  to: {
                                                    name: "Revenue",
                                                    query: { r: _vm.item.id },
                                                  },
                                                  target: "_self",
                                                },
                                              },
                                              [
                                                _vm._v(" Show Revenues "),
                                                _c("CIcon", {
                                                  attrs: {
                                                    name: "cil-arrow-right",
                                                    width: "16",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1448551969
                              ),
                            },
                            [
                              _c("CIcon", {
                                attrs: { name: "cil-bar-chart", width: "24" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        {
                          staticClass: "device-widget widget",
                          attrs: { col: "12", xl: "3" },
                        },
                        [
                          _c(
                            "CWidgetIcon",
                            {
                              attrs: {
                                header: "Device",
                                text: _vm.textLinkedDevice,
                                color: "gradient-dark",
                                "icon-padding": false,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "footer",
                                    fn: function () {
                                      return [
                                        _c(
                                          "CCardFooter",
                                          [
                                            _vm.linkedDevice
                                              ? _c(
                                                  "CButton",
                                                  {
                                                    attrs: {
                                                      color: "danger",
                                                      size: "sm",
                                                      shape: "pill",
                                                    },
                                                    on: {
                                                      click: _vm.unlinkDevice,
                                                    },
                                                  },
                                                  [
                                                    _c("CIcon", {
                                                      attrs: { name: "cil-x" },
                                                    }),
                                                    _vm._v(" Unlink Device "),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "CTabs",
                                                  {
                                                    attrs: {
                                                      "active-tab":
                                                        _vm.activeTab,
                                                    },
                                                    on: {
                                                      "update:activeTab":
                                                        function ($event) {
                                                          _vm.activeTab = $event
                                                        },
                                                      "update:active-tab":
                                                        function ($event) {
                                                          _vm.activeTab = $event
                                                        },
                                                    },
                                                  },
                                                  [
                                                    _vm.previousRestDevice
                                                      ? _c(
                                                          "CTab",
                                                          {
                                                            staticClass:
                                                              "tab-item",
                                                            attrs: {
                                                              title:
                                                                "Get From the Previous",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "ul",
                                                              {
                                                                staticClass:
                                                                  "pt-2",
                                                              },
                                                              [
                                                                _c("li", [
                                                                  _vm._v(
                                                                    "Previous Restaurant ID: #" +
                                                                      _vm._s(
                                                                        _vm
                                                                          .previousRestDevice
                                                                          .restaurant_id
                                                                      )
                                                                  ),
                                                                ]),
                                                                _c("li", [
                                                                  _vm._v(
                                                                    "Device SID: " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .previousRestDevice
                                                                          .device
                                                                          ?.sid
                                                                      ) +
                                                                      " (#" +
                                                                      _vm._s(
                                                                        _vm
                                                                          .previousRestDevice
                                                                          .device_id
                                                                      ) +
                                                                      ")"
                                                                  ),
                                                                ]),
                                                                _c("li", [
                                                                  _vm._v(
                                                                    "Software: " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .previousRestDevice
                                                                          .device
                                                                          ?.software
                                                                          ? "YES"
                                                                          : "NO"
                                                                      )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _c(
                                                              "CButton",
                                                              {
                                                                attrs: {
                                                                  type: "submit",
                                                                  color:
                                                                    "success",
                                                                  size: "sm",
                                                                  shape: "pill",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.linkDevice(
                                                                        $event,
                                                                        _vm.previousRestDevice
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("CIcon", {
                                                                  attrs: {
                                                                    name: "cil-pin",
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  " Link Device "
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "CTab",
                                                      {
                                                        staticClass: "tab-item",
                                                        attrs: {
                                                          title: "Select New",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "CForm",
                                                          {
                                                            staticClass: "pt-2",
                                                            on: {
                                                              submit:
                                                                _vm.linkDevice,
                                                            },
                                                          },
                                                          [
                                                            _c("v-select", {
                                                              staticClass:
                                                                "mb-2",
                                                              attrs: {
                                                                options:
                                                                  _vm.availableDevices,
                                                                placeholder:
                                                                  "Please choose a device..",
                                                                searchable: true,
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "search",
                                                                      fn: function ({
                                                                        attributes,
                                                                        events,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "input",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  staticClass:
                                                                                    "vs__search",
                                                                                  attrs:
                                                                                    {
                                                                                      required:
                                                                                        !_vm.selectedDevice,
                                                                                    },
                                                                                },
                                                                                "input",
                                                                                attributes,
                                                                                false
                                                                              ),
                                                                              events
                                                                            )
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  false,
                                                                  3166129734
                                                                ),
                                                              model: {
                                                                value:
                                                                  _vm.selectedDevice,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.selectedDevice =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "selectedDevice",
                                                              },
                                                            }),
                                                            _c(
                                                              "CButton",
                                                              {
                                                                attrs: {
                                                                  type: "submit",
                                                                  color:
                                                                    "success",
                                                                  size: "sm",
                                                                  shape: "pill",
                                                                },
                                                              },
                                                              [
                                                                _c("CIcon", {
                                                                  attrs: {
                                                                    name: "cil-pin",
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  " Link Device "
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                198154296
                              ),
                            },
                            [
                              _c("CIcon", {
                                attrs: { name: "cil-mobile", width: "24" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("CElementCover", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                attrs: { opacity: 0.4 },
              }),
              _c("ChangeHeroImage", {
                attrs: { imageId: _vm.item.hero_image?.id },
                on: { update: _vm.changedHeroImage },
              }),
              _c("ChangeLogo", {
                attrs: {
                  restaurantId: this.item.id,
                  slug: this.item.restaurant_slug,
                  logo: _vm.getValidImageUrl(this.item.restaurant_logo_path),
                },
                on: { update: _vm.changedlogo },
              }),
              _c("SendRestaurantDetails", {
                attrs: {
                  restaurantId: _vm.item.id,
                  emails: _vm.uniqueEmails,
                  detail: _vm.detailToSend,
                },
              }),
              _c("LatestActivities", {
                attrs: {
                  restaurant: {
                    id: _vm.item.id,
                    restaurant_name: _vm.item.restaurant_name,
                    restaurant_postcode: _vm.item.restaurant_postcode,
                  },
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            [
              _vm.loading
                ? _c("CSpinner", { attrs: { color: "primary" } })
                : _c("h6", [_vm._v("Restaurant not found!")]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }