var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CSidebar",
    {
      attrs: {
        aside: "",
        overlaid: "",
        colorScheme: "light",
        size: "lg",
        show: _vm.$store.state.changeLogoAsideShow,
      },
      on: {
        "update:show": (val) =>
          _vm.$store.commit("set", ["changeLogoAsideShow", val]),
      },
    },
    [
      _c("CSidebarClose", {
        nativeOn: {
          click: function ($event) {
            return _vm.$store.commit("toggle", "changeLogoAsideShow")
          },
        },
      }),
      _c(
        "CListGroup",
        { staticClass: "scrollable list-group-accent mt-5" },
        [
          _c(
            "CListGroupItem",
            {
              staticClass:
                "mb-0 list-group-item-accent-primary bg-light text-center font-weight-bold text-uppercase",
            },
            [_vm._v(" Change Logo ")]
          ),
          _c(
            "CListGroupItem",
            { staticClass: "list-group-item-accent-secondary" },
            [
              _c(
                "div",
                { staticClass: "image-container" },
                [
                  _c("CImg", {
                    staticClass: "logo",
                    attrs: {
                      src: _vm.previewImage,
                      placeholderColor: "lightgray",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex justify-content-between" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "btn btn-link m-0 p-0",
                      attrs: { for: "logo" },
                    },
                    [
                      _c("CIcon", {
                        staticClass: "mr-1",
                        attrs: { name: "cil-file" },
                      }),
                      _vm._v("Choose a new one "),
                    ],
                    1
                  ),
                  _c("CInputFile", {
                    staticStyle: { display: "none" },
                    attrs: { id: "logo" },
                    on: { change: _vm.onFileChanged },
                  }),
                  _c(
                    "CLink",
                    {
                      staticClass: "text-danger",
                      class: { "disable-item": !_vm.logo },
                      on: { click: _vm.removeLogo },
                    },
                    [
                      _c("CIcon", {
                        staticClass: "mr-1",
                        attrs: { name: "cil-trash" },
                      }),
                      _vm._v("Remove "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "CListGroupItem",
            { staticClass: "sticky-bottom list-group-item-accent-primary" },
            [
              _c(
                "CButton",
                {
                  attrs: {
                    color: "primary",
                    shape: "pill",
                    disabled: _vm.newLogo === null || _vm.loading,
                  },
                  on: { click: _vm.change },
                },
                [_vm._v("Change")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("CElementCover", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        attrs: { opacity: 0.4 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }